import { AxiosResponse } from 'axios';
import { initBehaviors } from '@/truserve/general';


// Flag to track whether we're already in a modal dialog, to keep from opening
// two by clicking fast enough.
let modalBusy = false;
let previouslyFocusedElement: HTMLElement | null = null;


/**
 * If possible, restore where saveFocus() saw the browser's focus was
 *
 */
function restoreFocus() {
    if (previouslyFocusedElement) {
        previouslyFocusedElement.focus();
        previouslyFocusedElement = null;
    }
}


/**
 * Save where the browser is currently focused
 *
 */
function saveFocus() {
    if (document.activeElement instanceof HTMLElement) {
        previouslyFocusedElement = document.activeElement;
    } else {
        previouslyFocusedElement = null;
    }
}


/**
 * Show a a BS4 modal dialog fetched from the specified URL
 *
 * @param url
 * @param successCallback called if tsAjaxSubmit is used and the server's response.success is true.
 */
export function showModalDialog(url: string, successCallback?: (response: AxiosResponse) => void) {
    if (modalBusy) {
        return;
    }

    modalBusy = true;

    const div = $('<div class="modal fade" tabindex="-1" role="dialog"></div>').appendTo('body');


    div.load(url, () => {
        initBehaviors(div);

        // Put the cursor on the first visible input of the newly shown form.
        div.on('shown.bs.modal', () => {
            div.find('input, textarea').not('input[type=hidden]').first().trigger('focus');
        });

        // Setup to destroy the containing div, and allow another
        // modal popup - when the modal is closed.
        div.on('hidden.bs.modal', () => {
            restoreFocus();
            div.remove();
            modalBusy = false;
        });

        if (successCallback) {
            div.on('truserve.modal.success', (event, response) => {
                successCallback(response);
            });
        }

        saveFocus();

        div.modal('show');
    });
}
