import { showModalDialog } from '@/truserve/modal_dialog';
import { ChartInfo, ChartRow, MapInfo } from '@/types';

interface ReportSettings {
    summary: {
        charts: ChartInfo[];
        maps: MapInfo[];
    };
    mapSettings: object;
    mapSettingsUS: object;
}

const chartSettings = {
    animation: 0,
    bgColor: 'FFFFFF',
    canvasBorderColor: 'FFFFFF',
    divLineColor: 'EEEEEE',
    exportEnabled: 1,
    exportAtClientSide: 1,
    exportShowMenuItem: 0,
    numDivLines: 0,
    plotGradientColor: ' ',
    showAlternateVGridColor: 0,
    showBorder: 0,
    showPlotBorder: 0,
    showShadow: 0,
    showYAxisValues: 0,
    use3DLighting: 0,
};


/**
 * Fudge group labels in a FusionCharts bar chart, does so by going in after the chart has been
 * rendered and tweaking some SVG (yuck), bolding the text and moving to be flush-left instead
 * of right
 */
function fixupProgramsGoals(div: JQuery) {
    const svg = div.find('svg');
    const pgLabels = svg.find('[class*="-dataset-Label-group"] tspan').toArray();

    svg.find('[class*="-datalabel"] tspan').each((index, valueElement) => {
        const value = $(valueElement);
        if (value.html() === '0') {
            value.parent().remove();

            const label = $(pgLabels[index]);
            label.parent().css('text-anchor', 'start').css('font-weight', 'bold');
            label.attr('x', 10);
        }
    });
}


function showBarChart(div: JQuery, data: ChartRow[]) {
    div.insertFusionCharts({
        type: 'Bar2D',
        dataSource: {
            chart: chartSettings,
            data,
        },
        dataFormat: 'json',
        width: 700,
        height: Math.max((data.length * 18) + 20, 120),
        renderer: 'javascript',
    });
}

function showPieChart(div: JQuery, data: ChartRow[]) {
    div.insertFusionCharts({
        type: 'Pie2D',
        dataSource: {
            chart: chartSettings,
            data,
        },
        dataFormat: 'json',
        width: '700',
        height: '400',
        renderer: 'javascript',
    });
}

/**
 *
 * @param div
 * @param data
 */
function showProgramsGoalsChart(div: JQuery, data: ChartRow[]) {
    div.insertFusionCharts({
        type: 'Bar2D',
        dataSource: {
            chart: chartSettings,
            data,
        },
        dataFormat: 'json',
        width: 700,
        height: (data.length * 18) + 20,
        renderer: 'javascript',
    }).on('fusionchartsdrawcomplete', () => {
        fixupProgramsGoals(div);
    });
}


function showCharts(settings: ReportSettings) {
    const template = $('#chart-template');
    const charts = settings.summary.charts || [];

    charts.forEach((chartInfo, index) => {
        const div = template.clone();
        const id = 'chart_' + index;
        div.attr('id', id);
        div.find('.js-taxonomy-label').text(chartInfo.label);

        if (chartInfo.label_key) {
            div.find('.js-taxonomy-label-key').text(chartInfo.label_key);
        } else {
            div.find('.js-taxonomy-label-key').text(chartInfo.label);
        }

        if (chartInfo.label_value) {
            div.find('.js-taxonomy-label-value').text(chartInfo.label_value);
        }

        const table = div.find('.ts-summary-data');
        const header = table.find('.header');

        if (chartInfo.extra_columns) {
            chartInfo.extra_columns.forEach((column) => {
                $('<th>').text(column.column_label).appendTo(header);
            });
        }

        chartInfo.data.forEach((row) => {
            const tr = $('<tr>');
            $('<td>').text(row.label).appendTo(tr);
            $('<td>').text(row.value).appendTo(tr);

            if (chartInfo.extra_columns) {
                chartInfo.extra_columns.forEach((column) => {
                    $('<td>').text(row[column.column_name]).appendTo(tr);
                });
            }

            tr.appendTo(table);
        });

        div.removeClass('d-none');
        div.appendTo('#taxonomy-charts');

        const renderDiv = div.find('.ts-summary-chart');

        const chartType = chartInfo.type || (chartInfo.facet.facet_is_multiple ? 'bar' : 'pie');

        switch (chartType) {
            case 'bar':
                showBarChart(renderDiv, chartInfo.data);
                break;

            case 'pie':
                showPieChart(renderDiv, chartInfo.data);
                break;

            case 'programs_goals':
                showProgramsGoalsChart(renderDiv, chartInfo.data);
                break;

            default:
                console.log('Unknown chart type for:', chartInfo);
        }
    });

    template.remove();
}


function showMaps(settings: ReportSettings) {
    const maps = settings.summary.maps || [];

    maps.forEach((mapInfo) => {
        const div = $('#map-' + mapInfo.map_name);
        const chart = mapInfo.map_name === 'usa' ? settings.mapSettingsUS : settings.mapSettings;

        const width = 900;
        const height = Math.min(600, Math.round(width / mapInfo.map_aspect));

        div.insertFusionCharts({
            type: 'maps/' + mapInfo.map_name,
            dataSource: {
                chart,
                colorrange: mapInfo.map_colors,
                data: mapInfo.data,
            },
            dataFormat: 'json',
            width,
            height,
            id: 'fusion-map-' + mapInfo.map_name,
            renderer: 'javascript',
        });
    });
}


export function initReport(settings: ReportSettings) {
    if (settings.summary) {
        showCharts(settings);
        showMaps(settings);
    }

    // Add behaviors to buttons on reports page
    $('.ts-summary-combo').tsReportSummaryToggleButtons();

    // Setup to handle switching between reporting on Activities or Impacts
    $('#set-report-type-form').find('a').on('click', function () {
        const a = $(this);
        const f = a.closest('form');
        const input = f.find('input[name=item_type]');
        const oldType = input.val();
        const newType = this.dataset.itemType;
        if (oldType !== newType) {
            input.val(newType as string);
            f.trigger('submit');
        }
    });

    // Show form for saving current report settings as a report template
    $('button.ts-save-settings').on('click', function () {
        const btn = $(this);
        showModalDialog('/report/save-settings', () => {
            btn.remove();
        });
    });
}
