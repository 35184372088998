import type { Options } from 'select2';

let initialized = false;

/**
 * If the jquery selection contains any inputs with .use-select2 then import the
 * select2 extension and apply to those fields
 *
 */
export function enableSelect2(selection: JQuery<Document | HTMLElement>) {
    const selects = selection.find('select.use-select2');

    if (selects.length) {
        import('select2')
            .then((module) => {
                // Some craziness to convince that the default import from
                // select2 is callable, needed to initialize select2 with jquery,
                // just importing it isn't enough for some strange reason
                //
                if (!initialized) {
                    ((module.default as unknown) as () => void)();
                    initialized = true;
                }

                selects.each(function () {
                    // eslint-disable-next-line @typescript-eslint/no-this-alias
                    const elem = (this as unknown) as HTMLSelectElement;

                    // eslint-disable-next-line no-nested-ternary
                    const minimumInputLength = elem.dataset.minimumInputLength
                        ? parseInt(elem.dataset.minimumInputLength, 10)
                        : (elem.dataset.ajaxUrl ? 1 : 0);

                    const params: Options = {
                        allowClear: true,
                        // dropdownParent: elem.parentNode,
                        minimumInputLength,
                        placeholder: elem.dataset.placeholder || '--- None ---',
                        theme: 'bootstrap4',
                        width: '100%',
                    };

                    if (elem.multiple) {
                        elem.name += '[]';
                    }

                    if (elem.dataset.ajaxUrl) {
                        params.ajax = {
                            url: elem.dataset.ajaxUrl,
                            dataType: 'json',
                            data(queryParams) {
                                return {
                                    q: queryParams.term, // search term
                                };
                            },
                            processResults(data) {
                                return {
                                    // The backend uses value and label properties, but
                                    // select2 has its own way of doing things with id
                                    // and text properties
                                    //
                                    results: data.map(
                                        (option: { value: string | number, label: string }) => ({
                                            id: option.value,
                                            text: option.label || option.value,
                                        })
                                    ),
                                };
                            },
                        };
                    }

                    $(elem).select2(params);

                    // When clicking on a select2, and there's a search field, move
                    // the cursor focus there.
                    //
                    $(elem).on('select2:open', () => {
                        const input = document.querySelector<HTMLElement>('.select2-container--open .select2-search__field');
                        if (input) {
                            input.focus();
                        }
                    });
                });
            });
    }
}

