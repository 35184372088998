import axios from 'axios';

import { initBehaviors } from '@/truserve/general';
import * as ajaxForm from '@/truserve/ajaxForm';
import * as textFieldURL from '@/truserve/textFieldURL';
import { showModalDialog } from '@/truserve/modal_dialog';

export { fiscalYearRange } from '@/truserve/general';
export { initReport } from '@/truserve/report';
export { showActivityMap } from '@/truserve/activity_map';
export { showModalDialog } from '@/truserve/modal_dialog';


export function tsInit() {
    initBehaviors($(document));

    ajaxForm.init();
    textFieldURL.init();

    // In a navbar or nav tabs, mark the active tab and setup to submit
    // the form before switching tabs.
    $('.nav-tabs').tsNavMarkActive().tsNavSubmitFirst();

    // Setup demographic groups to show a sum at the bottom of each column, updating as necessary
    $('.ts-demographic-group').tsDemographicGroup();

    // Setup wizard prev/next buttons, and jumping between steps
    $('div.wizard').tsWizardActions();

    $('button[data-url]').on('click', function () {
        window.location.href = $(this).attr('data-url') as string;
    });

    $('[data-popup-url]').on('click', function () {
        showModalDialog($(this).attr('data-popup-url') as string);
        return false;
    });

    /* Clear a filter form (such as on Activity or Org list pages) and then submit */
    $('button.ts-clear-filter').on('click', function () {
        const form = $(this).closest('form');
        form.find('input').val('');
        form.trigger('submit');
    });

    // When a select2 of class ts-org-group-copy-src changes, lookup the org group members
    // and fill them into a ts-org-group-copy-dest in the same form.
    $('.ts-org-group-copy-src').on('select2:select', function (evt) {
        const frm = $(this).closest('form');

        axios.get<{value: number; label: string}[]>('/lookup/org-group-members.json', {
            params: {
                org_group_id: evt.params.data.id,
            },
        }).then((response) => {
            // Convert what the backend sends to default DOM Option objects
            //  https://developer.mozilla.org/en-US/docs/Web/API/HTMLOptionElement/Option
            const newOptions = response.data.map(
                (option) => new Option(option.label, option.value.toString(), true, true)
            );

            // Append each new org option to the orgs multi-selector
            const orgsSelector = frm.find('.ts-org-group-copy-dest');

            newOptions.forEach((option) => {
                orgsSelector.append(option);
            });
        });
    });
}
